@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Black-Italic.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Black.ttf') format('opentype');
    font-weight: 900;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Black.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Black.ttf') format('opentype');
    font-weight: 900;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-ExtraBold-Italic.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Heavy-Italic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-ExtraBold.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Heavy.otf') format('opentype');
    font-weight: 800;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Bold-Italic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Bold.otf') format('opentype'),
      url(assets/fonts/Nexa/Nexa--Bold.otf) format(opentype);
    font-weight: 700;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-SemiBold-Italic.otf")
      format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Bold-Italic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-SemiBold.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Bold.otf') format('opentype');
    font-weight: 600;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Medium-Italic.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Book-Italic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Medium.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Book.otf') format('opentype');
    font-weight: 500;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Regular.otf") format("opentype"); */
    /* src: url("assets/fonts/Nexa/Nexa-Regular.ttf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Book.otf') format('opentype');
    font-weight: 400;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-Regular-Italic.otf") format("opentype"); */
    /* src: url("assets/fonts/Nexa/Nexa-Regular.ttf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Book.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Light-Italic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Light.otf') format('opentype'),
      url(assets/fonts/Nexa/Nexa--Light.otf) format(opentype);
    font-weight: 300;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-ExtraLight-Italic.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Light-Italic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    /* src: url("assets/fonts/Nexa/Nexa-ExtraLight.otf") format("opentype"); */
    src: url('assets/fonts/Nexa/Nexa-Light.otf') format('opentype');
    font-weight: 200;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Thin-Italic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: Nexa;
    src: url('assets/fonts/Nexa/Nexa-Thin.otf') format('opentype');
    font-weight: 100;
  }

  html,
  body,
  #root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow: auto;
    @apply text-text-primary font-Sora;
    scroll-behavior: smooth;
  }

  /* ::-webkit-scrollbar {
  display: none;
} */
}

.backgroundImage {
  background-image: url('assets/imgs/smiley-woman-backseat-her-car-looking-tablet 1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.deliveryBackgroundImage {
  background-image: url('assets/imgs/Rectangle 4.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.rideBackgroundImage {
  background-image: url('assets/imgs/Frame 281225.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutBackgroundImage {
  background-image: url('assets/imgs/image 10.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.logisticBackgroundImage {
  background-image: url('assets/imgs/Rectangle 7.png');
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .deliveryBackgroundImage {
    background-image: url('assets/imgs/Rectangle 4 2.png');
  }
}
